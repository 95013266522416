<template>
  <div class="apply-dev" v-wechat-title="'免费申请开发板'">
    <template v-if="applyDevStep == 1">
      <div class="product-list-head">
        <strong><span>免费</span>申请开发板</strong>
      </div>
      <div class="product-list-desc">
        <span
          >中软国际作为开放原子开源基金会开源鸿蒙项目的白金捐赠人，正在联合政府工信和大数据等主管部门做自主创新国产替代迁移及数字化转型工作。为了降低企业或开发者的研发成本，我们提供少量的开发板或模组，供企业或开发者免费申请。</span
        >
      </div>
      <div class="apply-dev-list-box">
        <p>请您选择需要申请的开发板</p>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="product-dev-list-box">
            <div
              class="product-iot-list"
              v-for="el in productList"
              :key="el.index"
              @click="productInfo(el.id)"
            >
              <span :class="{ active: productId == el.id }">
                <van-icon name="circle" class="icon-circle" />
                <van-icon name="checked" class="icon-checked" />
              </span>
              <div class="product-iot-list-img">
                <v-img
                  :attachment-id="el.coverAttmId"
                  :default-img="require('@/assets/img/kctp.png')"
                />
              </div>
              <div class="product-iot-list-info">
                <strong>{{ el.name }}</strong>
                <span v-if="el.datSocs"
                  >SOC：<template v-for="i in el.datSocs">{{
                    i.name
                  }}</template></span
                >
                <span>厂商：{{ el.isvName }}</span>
                <p v-if="el.sceneTagName">
                  <template v-for="(e, i) in el.sceneTagName.split(',')">
                    <span v-if="i < 2" :key="i">{{ e }}</span>
                  </template>
                </p>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </template>
    <div class="apply-dev-form" v-if="applyDevStep == 2">
      <p>
        <van-icon name="info" /><span
          >请准确填写收货人姓名、联系方式、地址等信息，确保试用产品能顺利到达您的手中！</span
        >
      </p>
      <strong>请您填写收货人信息</strong>
      <van-form validate-first @submit="onFailed">
        <van-field
          label="企业名称"
          required
          v-model="form.companyName"
          name="企业名称"
          placeholder="请输入企业名称"
          :rules="[{ required: true }]"
          class="apply-dev-form-inp"
        />
        <van-field
          label="收货人"
          required
          v-model="form.name"
          name="收货人"
          placeholder="请填写收货人姓名"
          :rules="[{ required: true }]"
          class="apply-dev-form-inp"
        />
        <van-field
          label="收货手机号"
          required
          v-model="form.contactPhone"
          name="收货手机号"
          maxlength="11"
          placeholder="请填写收货手机号"
          :rules="[{ required: true }]"
          class="apply-dev-form-inp"
        />
        <!-- <van-field
          label="验证码"
          required
          v-model="form.phoneCode"
          name="验证码"
          placeholder="请填写验证码"
          :rules="[{ required: true }]"
          class="apply-dev-form-inp"
        >
          <template #button
            ><span @click="sendMobileCode" style="color: #EA0B06">{{
              phoneCodeBtn ? `重新发送(${reciprocalNum})` : "获取验证码"
            }}</span></template
          >
        </van-field> -->
        <van-field
          label="收货地址"
          required
          v-model="form.address"
          type="textarea"
          name="收货地址"
          placeholder="请输入您的收货地址"
          :autosize="{ maxHeight: 100, minHeight: 100 }"
          :rules="[{ required: true }]"
          class="apply-dev-form-text"
        />
        <div class="apply-dev-form-btn">
          <van-button block class="btn-red" @click="applyDevStep = 1"
            >上一步</van-button
          >
          <van-button
            block
            class="btn-red"
            native-type="submit"
            :class="{
              'apply-dev-form-submit':
                form.companyName != '' &&
                form.name != '' &&
                form.contactPhone != '' &&
                form.address != '',
            }"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
    <div class="apply-dev-footer" v-if="applyDevStep == 1">
      <van-button class="btn-red" @click="next">下一步</van-button>
    </div>
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VImg from "@/components/VImg";
import { sendMobile,checkCaptchaMobile } from "@/api/common";
import { getIotDevList, devboardApply ,getProductList} from "@/api/iot";
import share from '../components/share.vue'
export default {
  components: {
    VImg,
    share
  },
  data() {
    return {
      productList: [],
      page: {
        current: 0,
        size: 9,
        pageSize: 1,
      },
      loading: false,
      finished: false,
      applyDevStep: 1,
      form: {
        companyName: "",
        name: "",
        contactPhone: "",
        // phoneCode: "",
        address: "",
      },
      reciprocalNum: 60,
      phoneCodeBtn: false,
      productId: "",
      shareInfo: {
          title: '免费申请开发板',
          desc: '为了降低企业或开发者的研发成本，我们提供少量的开发板或模组，供企业或开发者免费申请。',
          img: `${process.env.VUE_APP_OBS_PREFIX}/1554024176417849345`
      }
    };
  },
  computed: mapGetters(['userInfo']),
  mounted() {
    this.form.companyName = this.userInfo.companyName
    this.form.name = this.userInfo.realName
    this.form.contactPhone = this.userInfo.phone
    this.loadgetIotDevList();
  },
  methods: {
    onFailed(values) {
      let { form,productId } = this;
      let _this = this;
      let data = {
        devboardId: productId,
        companyName: form.companyName,
        contactPerson: form.name,
        contactPhone: form.contactPhone,
        address: form.address,
      };
      // checkCaptchaMobile({mobile:form.contactPhone,code:form.phoneCode}).then((res)=>{
      //   if(!res){
      //     this.$toast("验证码有误");
      //     return
      //   }
        devboardApply(data).then((res) => {
          this.$toast.success('提交成功！');
          this.form = {
            companyName: "",
            name: "",
            contactPhone: "",
            // phoneCode: "",
            address: "",
          }
          this.applyDevStep = 1
          this.$router.go(-1)
        });
      // })
    },
    sendMobileCode() {
      const { contactPhone, phoneCodeBtn } = this.form;
      if (this.phoneCodeBtn) {
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(contactPhone)) {
        this.$toast("手机号格式不正确");
      }
      sendMobile(contactPhone).then((code) => {
        this.$toast.success('发送成功');
        this.phoneCodeBtn = true;
        this.reciprocalItme();
      });
    },
    reciprocalItme() {
      let _this = this;
      let num = this.reciprocalNum;
      let itme = null;
      itme = setInterval(function () {
        num--;
        if (num <= 0) {
          _this.phoneCodeBtn = false;
          _this.reciprocalNum = 60;
          clearInterval(itme);
          return;
        }
        _this.reciprocalNum = num;
      }, 1000);
    },
    onLoad() {
      if (this.page.pageSize == this.page.current) {
        this.finished = true;
        return;
      }
      this.page.current++;
      this.loading = true;
      this.loadgetIotDevList();
    },
    loadgetIotDevList() {
      if(this.page.current == 0){
        return
      }
      getProductList(this.page, {
        isPossibleApply:1,
        status: '2',
        categoryId:'',
        type:'dev', }).then(
        (res) => {
          this.loading = false;
          this.productList = [...this.productList, ...res.records];
          this.page.pageSize = res.pages;
          this.finished = false;
        }
      );
    },
    next() {
      if (this.productId == "") {
        this.$toast("请您选择需要申请的开发板");
        return;
      }
      this.applyDevStep = 2;
    },
    productInfo(id) {
      this.productId = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.apply-dev {
  min-height: 100%;
  background: #f6f6f6;
  .product-list-head {
    background: #fff;
    padding: 38px 28px;
    background: url(../../assets/img/34@2x.png) no-repeat;
    background-size: 100% 100%;
    height: 160px;
    > strong {
      font-size: 20px;
      line-height: 28px;
      color: #333333;
      display: block;
      > span {
        color: #ea0b06;
      }
    }
  }
  .product-list-desc {
    padding: 0 16px;
    position: relative;
    z-index: 2;
    margin-top: -50px;
    > span {
      display: block;
      padding: 24px 12px;
      font-size: 12px;
      line-height: 18px;
      color: #666666;
      background: url(../../assets/img/5020@2xbg.png) no-repeat;
      background-size: 100% 100%;
      border-radius: 4px;
    }
  }
  .apply-dev-list-box {
    padding-bottom: 80px;
    margin-top: 24px;
    > p {
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      color: #333333;
      margin-bottom: 8px;
      padding: 0 16px;
    }
  }
  .product-dev-list-box {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .product-iot-list {
      width: 167px;
      background: #ffffff;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      margin-bottom: 9px;
      position: relative;
      > span {
        position: absolute;
        top: 4px;
        left: 4px;
        > i {
          font-size: 18px;
          color: #999999;
        }
        .icon-circle {
          display: block;
        }
        .icon-checked {
          display: none;
        }
        &.active {
          .icon-circle {
            display: none;
          }
          .icon-checked {
            display: block;
            color: #EA0B06;
          }
        }
      }
      .product-iot-list-img {
        position: relative;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        > span {
          position: absolute;
          width: 32px;
          height: 19px;
          left: 10px;
          top: -4px;
          > img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        > img {
          display: block;
          width: 90px;
        }
      }
      .product-iot-list-info {
        padding: 8px;
        > strong {
          display: block;
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
          color: #333333;
          margin-bottom: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #666666;
          display: block;
          margin-bottom: 5px;
        }
        > p {
          display: flex;
          margin-bottom: 0;
          > span {
            height: 19px;
            background: #f4f8f7;
            border-radius: 2px;
            padding: 0 6px;
            font-size: 12px;
            font-weight: 400;
            line-height: 19px;
            color: #333333;
            padding: 0 6px;
            margin-right: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .apply-dev-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: #fff;
    padding: 16px 16px 24px;
    > button {
      width: 100%;
      height: 44px;
    }
  }
}
.apply-dev-form {
  padding: 16px;
  > p {
    background: #fff5e6;
    border: 1px solid #ffe1b4;
    opacity: 1;
    border-radius: 4px;
    padding: 12px;
    font-size: 12px;
    line-height: 18px;
    color: #999999;
    display: flex;
    margin-bottom: 24px;
    > i {
      font-size: 16px;
      line-height: 18px;
      margin-right: 8px;
      color: #fe9400;
    }
  }
  > strong {
    display: block;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: #333333;
    margin-bottom: 24px;
  }
}
.apply-dev-form-btn {
  display: flex;
  margin-top: 40px;
  > button:first-child {
    margin-right: 10px;
  }
  > button:last-child {
    opacity: 0.5;
  }
  .apply-dev-form-submit {
    opacity: 1 !important;
  }
}
</style>
<style>
.apply-dev-form .van-cell {
  padding: 17px 12px;
}
.apply-dev-form .van-field__label {
  margin-left: 2px;
}
.apply-dev-form-inp .van-field__control{
  text-align: right;
}
.apply-dev-form-text{
  display: block;
}
.apply-dev-form-text .van-cell__value{
  width: 100%;
  padding: 10px;
  background: #F9F9FB;
  margin-top: 12px;
}
</style>
